import * as React from 'react'
import loadable from '@loadable/component'

const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))

const StepSmoker = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet legend={'Do you smoke?'} groupName={'smoker'} required>
            <FieldSpacer>
                <FeatureRadio
                    label={'Yes'}
                    groupName={'smoker'}
                    name={'smokerYes'}
                    value={'YES'}
                    icon={'smoker'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer>
                <FeatureRadio
                    label={'No'}
                    groupName={'smoker'}
                    name={'smokerNo'}
                    value={'NO'}
                    icon={'non-smoker'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepSmoker
